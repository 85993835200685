import { Picture } from '@components/ui'
import s from '../../Home.module.css'
import cn from 'classnames'
import { Logo } from '@components/icons/home'

/**
 * Renders the "WhyChoose" component.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.section - The section data.
 * @param {string} props.locale - The locale string.
 * @param {Object} props.shopCommon - The shop common data.
 * @param {Array} props.relatedProducts - The related products data.
 * @returns {JSX.Element} The rendered component.
 */
const WhyChoose = ({ section }) => {
  const { title, subtitle, card, lists } = section
  return (
    <div className="layer">
      <div className="content">
        <div className="overflow-hidden py-5 min-l:py-10 min-xl:py-[60px]">
          <h2
            className={s.title}
            dangerouslySetInnerHTML={{ __html: title }}
          ></h2>
          <div className="relative">
            {subtitle && (
              <p
                className="absolute right-0 top-[-9vw] hidden text-[9.3vw] font-bold text-[#094867] opacity-[0.04] min-l:block min-xl:text-[9.7vw] min-xxl:text-[150px]"
                dangerouslySetInnerHTML={{ __html: subtitle }}
              ></p>
            )}
            <div className="relative mt-6 grid grid-cols-2 gap-2 min-md:grid-cols-3 min-l:mt-8 min-l:h-[51vw] min-l:grid-cols-4 min-l:grid-rows-11 min-xl:mt-12 min-xl:h-[42vw] min-xxl:h-[37vw]">
              {card && (
                <div className="relative col-span-2 h-[184px] rounded-xl bg-brand-color p-4 text-sm font-medium !leading-[1] text-white min-md:col-span-3 min-l:col-span-2 min-l:row-span-6 min-l:h-auto min-xl:p-6 min-xl:text-[20px] min-xxl:text-[32px]">
                  <p dangerouslySetInnerHTML={{ __html: card.text1 }}></p>
                  <p
                    className="py-2 text-[40px] font-bold min-l:py-[10px] min-l:text-[56px] min-xl:text-[64px] min-xxl:text-[100px]"
                    dangerouslySetInnerHTML={{ __html: card.text2 }}
                  ></p>
                  <p dangerouslySetInnerHTML={{ __html: card.text3 }}></p>
                  <Logo className="absolute bottom-4 right-4 h-10 w-[85px] min-l:h-[43px] min-l:w-[91px] min-xl:bottom-6 min-xl:right-6 min-xl:h-10 min-xl:w-[85px] min-xxl:h-20 min-xxl:w-[169px]" />
                </div>
              )}
              {lists &&
                lists.map((item, index) => (
                  <div
                    key={index}
                    className={cn(
                      'relative h-[204px] rounded-xl bg-white p-4 min-l:h-auto min-xl:p-6',
                      {
                        'min-l:row-span-4': item['row-span'] === 4,
                        'min-l:row-span-5': item['row-span'] === 5,
                        'min-l:row-span-6': item['row-span'] === 6,
                        'min-l:row-span-7': item['row-span'] === 7,
                        'min-l:col-start-1': item['col-start'] === 1,
                        'min-l:col-start-2': item['col-start'] === 2,
                        'min-l:col-start-3': item['col-start'] === 3,
                        'min-l:col-start-4': item['col-start'] === 4,
                        'min-l:row-start-5': item['row-start'] === 5,
                      }
                    )}
                    style={{ background: item.background }}
                  >
                    <div className="relative z-[1]">
                      <h3
                        className="font-semibold !leading-[1.2] text-[#32739a] min-xl:text-[20px] min-xxl:text-2xl"
                        dangerouslySetInnerHTML={{ __html: item.title }}
                      ></h3>
                      <p
                        className="mt-[6px] text-sm font-medium min-xxl:text-2xl"
                        dangerouslySetInnerHTML={{ __html: item.subtitle }}
                      ></p>
                    </div>
                    <Picture
                      source={item.icon}
                      className={cn(
                        'absolute bottom-4 right-4 w-12 min-l:bottom-3 min-l:right-3 min-l:w-[60px] min-xl:bottom-6 min-xl:right-6 min-xxl:bottom-8 min-xxl:right-8 min-xxl:w-20',
                        {
                          '!bottom-0 !right-0 !w-full': item.full,
                        }
                      )}
                    />
                  </div>
                ))}

              {/* <div className="row-span-4 bg-white"></div>
            <div className="col-start-1 row-span-5 bg-white"></div>
            <div className="col-start-2 row-span-5 bg-white"></div>
            <div className="col-start-3 row-span-5 bg-white"></div>
            <div className="col-start-4 row-span-7 row-start-5 bg-white"></div> */}
            </div>
          </div>
          {/* <div className="mt-6 flex gap-3 overflow-auto pb-6 pl-6 pr-6 min-md:mt-10 min-md:pl-10 min-md:pr-10 min-l:gap-4 min-l:pl-0 min-l:pr-0 min-xl:mt-12">
            {section.card && (
              <div className="relative h-[368px] w-[204px] shrink-0 overflow-hidden rounded-xl bg-[#005d8e] min-l:h-[372px] min-xl:h-[546px] min-xl:w-[308px]">
                <Picture source={section.card.image} className="" />
                <div className="absolute bottom-0 left-0 flex h-[120px] w-full flex-col justify-center px-4 text-white min-l:h-[124px] min-l:px-[18px] min-xl:h-[170px] min-xl:px-[27px]">
                  <Logo className="w-[44px] text-white min-xl:w-[66px]" />
                  <h4
                    className="mt-[6px] line-clamp-2 text-2xl font-bold min-xl:text-4xl"
                    dangerouslySetInnerHTML={{ __html: section.card.title }}
                    title={section.card.title}
                  ></h4>
                </div>
              </div>
            )}
            {section.lists && (
              <div className="grid w-[644px] shrink-0 grid-cols-[repeat(24,1fr)] gap-3 min-l:w-[calc(100%-220px)] min-l:gap-4 min-xl:w-[calc(100%-324px)]">
                {section.lists?.map((item, index) => (
                  <div
                    key={index}
                    className={cn(
                      'relative h-[178px] overflow-hidden rounded-xl bg-[#f7f8f9] pt-5 min-xl:h-[265px] min-xl:pt-8',
                      {
                        'col-span-7': item._span === '7/24',
                        'col-span-8': !item._span || item._span === '8/24',
                        'col-span-10': item._span === '10/24',
                      }
                      // s.cardBox
                    )}
                  >
                    <h3
                      dangerouslySetInnerHTML={{ __html: item.title }}
                      className="px-4 text-sm font-semibold leading-[1.4] min-l:px-5 min-xl:px-8 min-xl:text-[20px]"
                    ></h3>
                    <div
                      className="absolute bottom-4 right-4 min-xl:bottom-[26px] min-xl:right-[26px]"
                      style={{
                        bottom: item.bottom,
                        right: item.right,
                      }}
                    >
                      <Picture
                        source={item.icon}
                        className={cn('mt-2 !overflow-visible', style.img)}
                        imgClassName="h-[60px] !w-auto min-xl:h-[87px]"
                        style={{
                          '--height': item.height || '60px',
                          '--height_l': item.height_l || '60px',
                          '--height_xl': item.height_xl || '87px',
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default WhyChoose
